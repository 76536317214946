import { render, staticRenderFns } from "./Creditos.vue?vue&type=template&id=dc6437ca&scoped=true&"
import script from "./Creditos.vue?vue&type=script&lang=ts&"
export * from "./Creditos.vue?vue&type=script&lang=ts&"
import style0 from "./Creditos.vue?vue&type=style&index=0&id=dc6437ca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc6437ca",
  null
  
)

export default component.exports