import { render, staticRenderFns } from "./Business.vue?vue&type=template&id=096938b0&scoped=true&"
import script from "./Business.vue?vue&type=script&lang=ts&"
export * from "./Business.vue?vue&type=script&lang=ts&"
import style0 from "./Business.vue?vue&type=style&index=0&id=096938b0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "096938b0",
  null
  
)

export default component.exports