import { render, staticRenderFns } from "./AlertaKhipu.vue?vue&type=template&id=748fa3ec&scoped=true&"
import script from "./AlertaKhipu.vue?vue&type=script&lang=ts&"
export * from "./AlertaKhipu.vue?vue&type=script&lang=ts&"
import style0 from "./AlertaKhipu.vue?vue&type=style&index=0&id=748fa3ec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "748fa3ec",
  null
  
)

export default component.exports